import React, { Fragment, useState, useEffect, useRef  } from 'react';
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom';
import Breadcrumb from '../layout/breadcrumb'
import * as XLSX from 'xlsx';
import moment from 'moment';
import DataTable from 'react-data-table-component'
import { tableData } from '../data/dummyTableData'
import {
  Container, Row, Col, Card, Label, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input, FormGroup, ButtonGroup
} from 'reactstrap'
import { postWithData, get } from '../services/api'
import { toast } from 'react-toastify';
import Select from 'react-select';
import '../assets/css/tablestyle.css';
const CandidateList = () => {

  const [perfilActive, setPerfilActive] = useState(localStorage.getItem('NameRole'));
  const [nameUserSesion, setNameUserSesion] = useState(localStorage.getItem('Name'));
  const [listPositionData, setListPositionData] = useState([]);

  const [listPositionExcelData, setListPositionExcelData] = useState([]);
  const [listPositionDataReserv, setListPositionDataReserv] = useState([]);
  const [listPositionDataReservForFilter, setListPositionDataReservForFilter] = useState([]);
  const [searchTextClient, setSearchTextClient] = useState('');
  const [searchTextPosition, setSearchTextPosition] = useState('');
  const [searchTextResponsableReclutador, setSearchTextResponsableReclutador] = useState('');
  const [searchTextDateRegister, setSearchTextDateRegister] = useState('');
  const [searchTextCodePosition, setSearchTextCodePosition] = useState('');
  const [searchTextPriority, setSearchTextPriority] = useState('');
  const [searchTextStatus, setSearchTextStatus] = useState('');
  const [searchTextId, setSearchTextId] = useState('');
  const [indexEditData, setIndexEditData] = useState('')

  const [loading, setLoading] = useState(true);
  const [visibleSearch, setVisibleSearch] = useState(true);

  const [visibleModalListCandidate, setVisibleModalListCandidate] = useState(false);
  const [listCandidates, setListCandidates] = useState([]);
  const [visibleModalAddAddCandidates, setVisibleModalAddAddCandidates] = useState(false);
  const [visibleModalDeletePosition, setVisibleModalDeletePosition] = useState(false);

  const [visibleModalEditPriority, setVisibleModalEditPriority] = useState(false);
  const [visibleModalEditState, setVisibleModalEditState] = useState(false);
  
  const [priorityValue, setPriorityValue] = useState();
  const [stateValue, setStateValue] = useState();
  const [stateSubValue, setStateSubValue] = useState();
  const [multiplePriority, setMultiplePriority] = useState();
  const [multipleState, setMultipleState] = useState();
  const [multipleSubState, setMultipleSubState] = useState();

  const [listCandidatesShow, setListCandidatesShow] = useState([]);
  const [listCandidatesInfo, setListCandidatesInfo] = useState([]);
  const { register, handleSubmit, errors } = useForm();
  const [phoneCandidate, setPhoneCandidate] = useState(null);
  const [mailCandidate, setMailCandidate] = useState([]);

  const [idCandidate, setIdCandidate] = useState(null);
  const [idPosition, setIdPosition] = useState(null);
  const [namePosition_, setNamePosition_] = useState(null);
  const [priorityPosition, setPriorityPosition] = useState(null);
  const [statePosition, setStatePosition] = useState(null);

  const [listStatus, setListStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({});
  
  const [listSubStatus, setListSubStatus] = useState([]);
  const [selectedSubStatus, setSelectedSubStatus] = useState({});

  const [listStatusReject, setListStatusReject] = useState([]);
  const [selectedStatusReject, setSelectedStatusReject] = useState({});
    
  const [isFilterPortal, setIsFilterPortal] = useState(false);
  const [isFilterNoPortal, setIsFilterNoPortal] = useState(false);

  const openModalDetail = () => setVisibleModalDetail(!visibleModalDetail);
  const [visibleModalDetail, setVisibleModalDetail] = useState(null);
  const [titleModalDetail, setTitleModalDetail] = useState(null);

  const [descriptions, setDescriptions] = useState(null);
  
  const [reqDeseable, setReqDeseable] = useState(null);
  const [reqIndispenable, setReqIndispenable] = useState(null);
  const [rateOrientative, setRateOrientative] = useState(null);
  const [togglePortalChange, setTogglePortalChange] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const checkboxRefs = useRef([]);
  const [showMaviseMessgeModal, setShowMaviseMessgeModal] = useState(false);
  const [showInputMotivo, setShowInputMotivo] = useState(false);
  const [motivoStatus, setMotivoStatus] = useState("");
  const [listPositionsDataGeneral, setListPositionsDataGeneral] = useState([]);

  useEffect(() => {
    getListCandidate();
    getListStatus();
    getListStatusReject();
  }, [0])
 
  useEffect(() => {
    clearInputs();
    if(togglePortalChange === 1){
      setListPositionData(listPositionDataReservForFilter.filter((item) => item.is_portal === 1));
      setListPositionDataReserv(listPositionDataReservForFilter.filter((item) => item.is_portal === 1));
    }else if (togglePortalChange === 2){
      setListPositionData(listPositionDataReservForFilter.filter((item) => item.is_portal === 0));
      setListPositionDataReserv(listPositionDataReservForFilter.filter((item) => item.is_portal === 0));
    } else {
      setListPositionData(listPositionDataReservForFilter);
      setListPositionDataReserv(listPositionDataReservForFilter);
    }
  }, [togglePortalChange])

  useEffect(() => {
    switch (perfilActive) {
      case "Recruiter":
        setListPositionsDataGeneral(tableColumnsRecruiting)
        break;
      case "Comercial":
        setListPositionsDataGeneral(tableColumnsOtherUsers)
        break;
      case "RRHH Manager":
        setListPositionsDataGeneral(tableColumnsManager)
        break;
      default :
        setListPositionsDataGeneral(tableColumnsOtherUsers)
        break;
    }
  }, [0])
  
  const getListCandidate = async () => {

    try {
      let dataList = [];
      let dataListExcel = [];

      get("api/position/list").then((result) => {
        if (result.data.length > 0) {

          result.data.forEach((element, index) => {
            let statusNameShow;

            let idPosicion = element.id;
            switch (element.state_value) {
              case "Ganada":
                statusNameShow = <p style={{fontSize:12, color:"#28a745", fontWeight:500 }}>{element.state_value}</p>
                break;
              case "Perdido/Cerrado":
                statusNameShow = <p style={{fontSize:12, color:"#dc3545", fontWeight:500  }}>{element.state_value}</p>
                break;
              case "En proceso":
                statusNameShow = <p style={{fontSize:12, color:"#5bc0de", fontWeight:500 }}>{element.state_value}</p>
                break;
              case "Parada":
                statusNameShow = <p style={{fontSize:12, color:"#ffc107", fontWeight:500 }}>{element.state_value}</p>
                break;
            }

            let priorityShow;

            switch (element.priority) {
              case "Baja":
                priorityShow = <span style={{padding: 8, color:"#22bb33", fontWeight:500 }} >{element.priority}</span>;
                break;
              case "Alta":
                priorityShow = <span style={{padding: 8, color:"#bb2124", fontWeight:500 }}>{element.priority}</span>;
                break;
              case "Media":
                priorityShow = <span style={{padding: 8, color:"#f0ad4e", fontWeight:500 }}>{element.priority}</span>;
                break;
            }

            let candidateLives;

            switch (true) {
              case element.cant_vivos  <= 1:
                candidateLives = <p className="d-flex justify-content-center align-items-center"><span style={{ fontWeight:500, marginRight:10, width: 20}}>
                  {<Button style={{ padding: 0,}} color='red' onClick={() => showDetailListCandiate(element.list_vivos, idPosicion)} > {element.cant_vivos} </Button>} 
                  </span> <span style={{display: "inline-block", padding: 8, background:"#bb2124", fontWeight:500, width:5, height:5, borderRadius:"50%" }} > </span></p>;
                break;
              case element.cant_vivos == 2 || element.cant_vivos == 3:
                candidateLives = <p className="d-flex justify-content-center align-items-center"><span style={{ fontWeight:500, marginRight:10, width: 20}}>
                  {<Button style={{ padding: 0,}} color='red' onClick={() => showDetailListCandiate(element.list_vivos, idPosicion)} > {element.cant_vivos} </Button>} 
                </span> <span style={{display: "inline-block", padding: 8, background:"#f0ad4e", fontWeight:500, width:5, height:5, borderRadius:"50%" }} > </span></p>;
                break;
              case element.cant_vivos  >= 4:
                candidateLives = <p className="d-flex justify-content-center align-items-center"><span style={{ fontWeight:500, marginRight:10, width: 20}}>
                  {<Button style={{ padding: 0,}} color='red' onClick={() => showDetailListCandiate(element.list_vivos, idPosicion)} > {element.cant_vivos} </Button>} 
                  </span> <span style={{display: "inline-block", padding: 8, background:"#22bb33", fontWeight:500, width:5, height:5, borderRadius:"50%" }} > </span></p>;
                break;
            }

            let canAddCandidate = false;
            if(element.responsable != null){
              canAddCandidate = (element.responsable).includes(nameUserSesion);
            }

            const GetButtons = () => {
              let statusAsignShow;
              if (element.state_value === 'En proceso' || element.state_value === 'Parada') {
                if(canAddCandidate == true){
                  statusAsignShow = <Button style={{ padding: 0, margin: 5, hight: '35px', width: '35px' }} color='red' onClick={() => openModalAddCandidates(element.id)} id={element.id}><i className= "icon-user-follow"></i></Button>;
                }else{
                  statusAsignShow = <Button style={{ padding: 0, margin: 5, hight: '35px', width: '35px' }} color='red' />;
                }
              } else {
                statusAsignShow = <Button style={{ padding: 0, margin: 5, hight: '35px', width: '35px' }} color='red' />;
              }

              
              let downloadFile;
              if (element.link_file != null && element.link_file != '0') {
                //downloadFile = <a href={element.path} target="_blank"> <i className= "icon-cloud-download"></i></a>
                //downloadFile = <a href={element.path} target="_blank"> <i className= "icon-cloud-download"></i></a>
                downloadFile = <Button style={{ padding: 5, hight: '35px', width: '35px' }} color='red'  title='Descargar Archivo' onClick={() => downloadFiles(element.link_file, element.name_archive)}><i className= "icon-cloud-download"></i></Button>;
              } else {
                downloadFile = "";
              }

              let duplicarVacante = <Button style={{ padding: 5, hight: '35px', width: '35px' }} color='red'  title='Duplicar Vacante' onClick={() => duplicatePosition(element.id)}><i className= "icon-docs"></i></Button>;
              
              let checkbox_ = (
                <input
                  type="checkbox"
                  ref={(el) => (checkboxRefs.current[index] = el)}
                  value={element.id}
                />
              );

              return (
                <ButtonGroup>
                  {statusAsignShow}
                  {/*<Button style={{ padding: 5, hight: '35px', width: '35px' }} color='red'  title='Descargar Briefing' onClick={() => downloadBriefing(element.id)}><i className= "icon-cloud-download"></i></Button> */}
                  {downloadFile}
                  {duplicarVacante}
                  {/* <Button style={{ padding: 5, hight: '35px', width: '35px' }} title='Editar'  color='red'><Link to={`/candidate/edit/${element.id}`}><i className= "icon-pencil"></i></Link></Button> */}
                  <Link to={`/position/edit/${element.id}`}><Button style={{ padding: 5, hight: '35px', width: '35px' }} title='Editar'  color='red' className= "icon-pencil"></Button></Link>
                  <Button style={{ padding: 5, hight: '35px', width: '35px' }} color='red'  title='Eliminar' onClick={() => openModalDeletePosition(element.id, element.name)}><i className= "icon-trash"></i></Button>
                 </ButtonGroup>
              )
            }

            let excelItem = {
              "Id": element.id,
              "Estado": element.state_value,
              "Cliente": element.name_client,
              "Responsable": element.responsable,
              "Vacante": element.name,
              "Fecha de Alta": (element.date_register).split(" ")[0],
              "Prioridad": element.priority,
              "Candidatos Activos": element.cant_vivos,
              "Candidatos Rechazados": element.cant_rechazados
            }
            

            let item = {
              "id": element.id,
              "checkbox": false,
              "name": <Button style={{ padding: 0,}} color='red' onClick={() => showDetail(element)} > {element.name} </Button>,
              "code_position": element.code_position ?? '',
              "name_client": element.name_client ?? '',
              "cant_propuesto": <Button style={{ padding: 0,}} color='red' onClick={() => showDetailListCandiate(element.list_propuesto, 0)} > {element.cant_propuesto} </Button>, 
              "cant_vivos": candidateLives ?? '', 
              "cant_rechazados": <Button style={{ padding: 0,}} color='red' onClick={() => showDetailListCandiate(element.list_rechazados, 0)} > {element.cant_rechazados} </Button>, 
              "description": element.description,
              "responsable_client": element.responsable_client,
              "responsable":(element.responsable == null || element.responsable == undefined) ? "" : (element.responsable).replace("null", ''),
              //"priority": priorityShow ?? '',
              "priority": element.priority ?? '',
              "range_salary": element.range_salary,
              "date_register": element.date_register,
              //"enabled": statusNameShow ?? '',
              "enabled": element.state_value,
              "sub_state": element.sub_state_value,
              "remote_work": (element.remote_work == 1) ? "Si" : "No",
              "actions": <GetButtons />,
              "is_portal": element.is_portal,
              "desirable": element.desirable,
              "indispensable": element.indispensable,
              "technology_minimal": element.technology_minimal,
              "technology_desirable": element.technology_desirable,
              "schedule": element.schedule,
              "desirable_requirements": element.desirable_requirements,
              "required_requirements": element.required_requirements,
              "orientative": element.orientative,
              "btnPriority" : <Button style={{ padding: 5, hight: '35px', width: '35px' }} color='red'  title='Cambiar Prioridad' onClick={() => openModalEditPriority(element.name, element.id, element.priority, index)}><i className= "icon-arrow-up"></i></Button>,
              "btnState" : <Button style={{ padding: 5, hight: '35px', width: '35px' }} color='red'  title='Cambiar Estado' onClick={() => openModalEditState(element.name, element.id, element.state_value, element.state_position_id, element.sub_state_value, element.sub_state_position_id, index)}><i className= "icon-disc"></i></Button>
              
            }
            dataList.push(item);
            dataListExcel.push(excelItem);
          });

          setListPositionData(dataList);
          setListPositionDataReserv(dataList);
          setListPositionDataReservForFilter(dataList);
          setListPositionExcelData(dataListExcel);
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getListStatus = async () => {
    let dataRes = [];
    try {
        get("api/position/state/list").then((result) => {
            if (result.success == true) {
                result.data.forEach(element => {
                    dataRes.push({value: element.id, label: element.value});
                });
                setListStatus(dataRes);
            }
        });
    } catch (error) {
        console.log(error)
    }
  }

  const getSubStatus = async (id_state) => {
    let dataRes = [];
    try {
        get("api/position/state/sub/list/"+id_state).then((result) => {
            if (result.success == true) {
                result.data.forEach(element => {
                    dataRes.push({value: element.id, label: element.value});
                });
                setListSubStatus(dataRes);
            }
        });
    } catch (error) {
        console.log(error)
    }
  }

  const getListStatusReject = async () => {
    let dataReject = [];
    try {
      get("api/status/list").then((result) => {
        if (result.success == true) {
          result.data.forEach(element => {
            if(element.name.indexOf('Rechazado') == 0){
              dataReject.push({value: element.id, label: element.name});
            }
          });
          setListStatusReject(dataReject);
        } 
      });

    } catch (error) {
        console.log(error)
    }
  }

  const downloadBriefing = (idPosition) => {

    try {
      get("api/position/generate/briefing/" + idPosition).then((result) => {

        const elementA = document.createElement("a");
        elementA.href = result.data.url;
        elementA.download = result.data.name;
        elementA.click();
      });
    } catch (error) {
      console.log(error)
    }
  }

  const downloadFiles = (path_file, name_file) => {
    /*
    try {
      get("api/archive/position/download/" + idPosition).then((result) => {
        console.log("Result", result)
        const elementA = document.createElement("a");
        elementA.href = result.data.url;
        elementA.download = result.data.nameFile;
        elementA.click();
      });
    } catch (error) {
      console.log(error)
    }*/
      let link = "https://api-recruiting.ioon.es/storage/"+path_file.replace("public/", '')
      //console.log(link)
      const elementA = document.createElement("a");
      elementA.href = link;
      elementA.download = name_file;
      elementA.click();
  }

  const duplicatePosition = (id) => {
    
    try {
      postWithData("api/position/replicate", {position_id : id}).then((result) => {
        
        if (result.success === true) {
          toast.success("Vacante duplicada");
        } else {
          toast.error("Hubo un error al duplucar la vacante")
        }
                    
      });
    } catch (error) {
      console.log(error)
    }
  }

  const openModalDeletePosition = (idPosition, namePosition) => {
    setNamePosition_(namePosition);
    setIdPosition(idPosition);
    setVisibleModalDeletePosition(true);
  }

  const openModalEditPriority = (namePosition, idPosition, priority, rowIndex) => {
    
    //getSelectedItems()

    setIndexEditData(rowIndex)
    setNamePosition_(namePosition);
    setPriorityValue({ value: priority, label: priority });
    setIdPosition(idPosition);
    setPriorityPosition(priority);
    setVisibleModalEditPriority(true);
  }

  const openModalEditState = (namePosition, idPosition, state, state_position_id, sub_state, sub_state_position_id, rowIndex) => {
  
    // element.name, element.id, element.state_value, element.state_position_id, element.sub_state_value, index
    getSubStatus(state_position_id);
    
    setIndexEditData(rowIndex)
    setNamePosition_(namePosition);
    setSelectedStatus({ value: state, label: state });
    setStateValue(state_position_id);
    setMultipleState(state)

    setStateSubValue({ value: sub_state_position_id, label: sub_state })
    setSelectedSubStatus({ value: sub_state_position_id, label: sub_state });

    setIdPosition(idPosition);
    setStatePosition(state_position_id);
    setVisibleModalEditState(true);
  }

  const deleteRegister = () => {
    let user_id = localStorage.getItem('Id');

    try {
      get("api/position/delete/" + idPosition + '/' + user_id).then((result) => {

        const newDatalist = listPositionData.filter(item => item.id !== idPosition);
        setListPositionData(newDatalist);
        setVisibleModalDeletePosition(false);
        toast.success(`Se elimino de forma satisfactoria.`);
                    
      });
    } catch (error) {
      console.log(error)
    }
  }

  const handleSelectPriority = (data) => {
      setPriorityValue({ value: data.value, label: data.value });
      setMultiplePriority(data.value);
  };

  const handleSelectState = (data) => {
      setSelectedSubStatus({ value: '', label: '' });
      setStateSubValue('');
      setMultipleSubState('')
      //console.log(data.value)
      setSelectedStatus({ value: data.value, label: data.label });
      setStateValue(data.value);
      setMultipleState(data.label)

      getSubStatus(data.value)
  };

  const handleSelectSubState = (data) => {
    setSelectedSubStatus({ value: data.value, label: data.label });
    setStateSubValue(data.value);
    setMultipleSubState(data.label)
    if(data.value == 12){ //Otros
      setShowInputMotivo(true);
    }else{
      setShowInputMotivo(false);
    }
};

const handleCheckboxChange = (id) => {
  /*
  const updatedData = listPositionData.map(item =>
    item.id === id ? { ...item, checkbox: !item.checkbox } : item // Solo cambia el checkbox a true para el id coincidente
  );
  //console.log("updatedData", updatedData);
  setListPositionData(updatedData); // Actualiza el estado con la lista modificada
  */

  setSelectedIds((prevIds) =>
    prevIds.includes(id) 
      ? prevIds.filter((existingId) => existingId !== id) 
      : [...prevIds, id]
  );
};

const handleChangeMotivo = (event) => {
  console.log(event.target.value);
  setMotivoStatus(event.target.value)
}

/*
const getSelectedItems = () => {
  const selectedItems = listPositionData.filter(item => item.checkbox).map(item => item.id);
  return selectedItems;
};
*/

const changePrioriy = () => {

    try {
      //let arrayMasivo = getSelectedItems();
      let arrayMasivo = selectedIds;
      let changeMasivo = (arrayMasivo.length > 0)? true : false;
      console.log(arrayMasivo)
      
      if(changeMasivo){
        setShowMaviseMessgeModal(true)
      }else{
        setShowMaviseMessgeModal(false)
      }

      const dataPriority = {
        position_id: (changeMasivo)? arrayMasivo : idPosition,
        masive: changeMasivo,
        priority: multiplePriority,
        user_id: localStorage.getItem('Id')
      }
      
      postWithData("api/position/priority", dataPriority).then((result) => {
        
        //setListPositionData(newDatalist);
        setVisibleModalEditPriority(false);
        if(changeMasivo){
          toast.success(`Cambio MASIVO de prioridad satisfactorio`);
          window.location.href = window.location.href;
        }else{
          handleChangeCellValue(indexEditData, 'priority', multiplePriority)
          toast.success(`Cambio de prioridad satisfactorio`);
          setShowMaviseMessgeModal(false)
        }
        
        
                    
      });

    } catch (error) {
      console.log(error)
    }
    
    
  }

  const changeState  = () => {

    try {
      //let arrayMasivo = getSelectedItems();
      let arrayMasivo = selectedIds;
      let changeMasivo = (arrayMasivo.length > 0)? true : false;

      if(changeMasivo){
        setShowMaviseMessgeModal(true)
      }else{
        setShowMaviseMessgeModal(false)
      }

      const dataState = {
        position_id: (changeMasivo)? arrayMasivo : idPosition,
        masive: changeMasivo,
        state: stateValue,
        sub_state: stateSubValue,
        motivo_status : motivoStatus,
        user_id: localStorage.getItem('Id')
      }
      
      postWithData("api/position/state", dataState).then((result) => {
        setVisibleModalEditState(false);
        
        if(changeMasivo){
          toast.success(`Cambio MASIVO de estado satisfactorio`);
          window.location.href = window.location.href;
        }else{
          handleChangeCellValue(indexEditData, 'enabled', multipleState)
          handleChangeCellValue(indexEditData, 'sub_state', multipleSubState)
          toast.success(`Cambio de estado satisfactorio`);
          setShowMaviseMessgeModal(false)
        }
        
                 
      });
    } catch (error) {
      console.log(error)
    }
  }

  const disassociatePosition = async (id_candidate, id_position) => {
    
    try {
      let data = {
        id_position : id_position,
        id_candidate : id_candidate
      }
      
      postWithData("api/position/candidate/disassociate", data).then((result) => {

        //setVisibleModalDesasociarPosition(false);
        toast.success(`Se desvinculo esta vacante de forma satisfactoria.`);
        //window.location.href = window.location.href;     
               
      });
       
    } catch (error) {
      console.log(error)
    }
    
  }

  const optionsPriority = [
    { value: 'Muy Alta', label: 'Muy Alta' },
    { value: 'Alta', label: 'Alta' },
    { value: 'Media', label: 'Media' },
    { value: 'Baja', label: 'Baja' },
]

  const tableColumnsManager = [
    
    {
      name: 'Cambio Masivo',
      cell: row => (
        <input
          type="checkbox"
          checked={row.checkbox || false}
          onChange={() => handleCheckboxChange(row.id)} // Llama a la función al cambiar
        />
      ),
      width: '50px', // Puedes ajustar el ancho según sea necesario
    },
    {
      name: 'Acciones',
      selector: 'actions',
      sortable: true,
      center: true,
      width: '300px',
    },
    {
      name: 'Fecha de Alta',
      selector: 'date_register',
      center: true,
      sortable: true,
      width: '170px'
    },
    {
      name: 'Id',
      selector: 'id',
      sortable: true,
      width: '80px',
    },
    {
      name: 'Vacante',
      selector: 'name',
      sortable: true,
      width: '250px',
      cell: (row) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'left' }}>
          {row.name}
        </div>
      ),
    },
    {
      name: 'Reclutador Responsable',
      selector: 'responsable',
      sortable: true,
      width: '250px',
    },
    {
      name: 'Candidatos Activos',
      selector: 'cant_vivos',
      sortable: true,
      center: true,
      width: '90px',
    },
    {
      name: 'Estado',
      selector: 'enabled',
      sortable: true,
      width: '150px'
    },
    {
      name: 'Sub-Estado',
      selector: 'sub_state',
      sortable: true,
      width: '300px'
    },
    {
      name: 'Prioridad',
      selector: 'priority',
      sortable: true,
      //center: true,
      width: '120px',
    },
    {
      name: 'Cambiar Prioridad',
      selector: 'btnPriority',
      sortable: false,
    },
    {
      name: 'Cambiar Estado',
      selector: 'btnState',
      sortable: false,
    },
    {
      name: 'Cliente',
      selector: 'name_client',
      sortable: true,
      width: '150px',
    },
    
    /*
    {
      name: 'Código',
      selector: 'code_position',
      sortable: true,
      width: '150px'
    },*/
    
    {
      name: 'Candidatos Rechazados',
      selector: 'cant_rechazados',
      sortable: true,
      center: true,
      width: '150px',

    }
  ]

  const tableColumnsRecruiting = [
    
    {
      name: 'Cambio Masivo',
      cell: row => (
        <input
          type="checkbox"
          checked={row.checkbox || false}
          onChange={() => handleCheckboxChange(row.id)} // Llama a la función al cambiar
        />
      ),
      width: '50px', // Puedes ajustar el ancho según sea necesario
    },
    {
      name: 'Acciones',
      selector: 'actions',
      sortable: true,
      center: true,
      width: '300px',
    },
    {
      name: 'Fecha de Alta',
      selector: 'date_register',
      center: true,
      sortable: true,
      width: '170px'
    },
    {
      name: 'Prioridad',
      selector: 'priority',
      sortable: true,
      //center: true,
      width: '120px',
    },
    {
      name: 'Id',
      selector: 'id',
      sortable: true,
      width: '80px',
    },
    {
      name: 'Vacante',
      selector: 'name',
      sortable: true,
      width: '250px',
      cell: (row) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'left' }}>
          {row.name}
        </div>
      ),
    },
    {
      name: 'Reclutador Responsable',
      selector: 'responsable',
      sortable: true,
      width: '250px',
    },
    {
      name: 'Candidatos Activos',
      selector: 'cant_vivos',
      sortable: true,
      center: true,
      width: '90px',
    },
    {
      name: 'Estado',
      selector: 'enabled',
      sortable: true,
      width: '150px'
    },
    {
      name: 'Sub-Estado',
      selector: 'sub_state',
      sortable: true,
      width: '300px'
    },
    {
      name: 'Cambiar Prioridad',
      selector: 'btnPriority',
      sortable: false,
    },
    {
      name: 'Cambiar Estado',
      selector: 'btnState',
      sortable: false,
    },
    {
      name: 'Cliente',
      selector: 'name_client',
      sortable: true,
      width: '150px',
    },
    {
      name: 'Candidatos Rechazados',
      selector: 'cant_rechazados',
      sortable: true,
      center: true,
      width: '150px',

    }
  ]

  const tableColumnsOtherUsers = [
    
    {
      name: 'Cambio Masivo',
      cell: row => (
        <input
          type="checkbox"
          //checked={row.checkbox || false}
          onChange={() => handleCheckboxChange(row.id)} // Llama a la función al cambiar
        />
      ),
      width: '50px', // Puedes ajustar el ancho según sea necesario
    },
    {
      name: 'Acciones',
      selector: 'actions',
      sortable: true,
      center: true,
      width: '300px',
    },
    {
      name: 'Cliente',
      selector: 'name_client',
      sortable: true,
      width: '150px',
    },
    {
      name: 'Jefe Responsable',
      selector: 'responsable_client',
      sortable: true,
      width: '150px',
    },
    {
      name: 'Reclutador Responsable',
      selector: 'responsable',
      sortable: true,
      width: '250px',
    },
    {
      name: 'Id',
      selector: 'id',
      sortable: true,
      width: '80px',
    },
    {
      name: 'Vacante',
      selector: 'name',
      sortable: true,
      width: '250px',
      cell: (row) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'left' }}>
          {row.name}
        </div>
      ),
    },
    {
      name: 'Prioridad',
      selector: 'priority',
      sortable: true,
      //center: true,
      width: '120px',
    },
    {
      name: 'Cambiar Prioridad',
      selector: 'btnPriority',
      sortable: false,
    },
    {
      name: 'Candidatos Activos',
      selector: 'cant_vivos',
      sortable: true,
      center: true,
      width: '90px',
    },
    {
      name: 'Estado',
      selector: 'enabled',
      sortable: true,
      width: '150px'
    },
    {
      name: 'Sub-Estado',
      selector: 'sub_state',
      sortable: true,
      width: '300px'
    },
    {
      name: 'Cambiar Estado',
      selector: 'btnState',
      sortable: false,
    },
    
    {
      name: 'Fecha de Alta',
      selector: 'date_register',
      center: true,
      sortable: true,
      width: '170px'
    },
    {
      name: 'Candidatos Rechazados',
      selector: 'cant_rechazados',
      sortable: true,
      center: true,
      width: '150px',

    }
  ]

  const tableColumnsCandidate = [
    {
      name: 'Nombre y Apellidos',
      selector: 'full_name_completo',
      sortable: true,
      center: false,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      center: false,
    },
    {
      name: 'Teléfono',
      selector: 'phone',
      sortable: true,
      center: false,
    },
    {
      name: 'Reclutador',
      selector: 'recruiter',
      sortable: true,
      center: false,
    },
    {
      name: 'Dar de baja',
      selector: 'baja',
      sortable: true,
      center: false,
      width: '200px'
    }
    
  ]

  // Modal ------------------------------------------------------------------------------------- Modal
  const closeModal = () => {
    setIndexEditData('')
    setVisibleModalAddAddCandidates(false);
    setVisibleModalListCandidate(false);
    setVisibleModalDeletePosition(false);
    setVisibleModalEditPriority(false);
    setVisibleModalEditState(false);

    setListCandidatesShow([]);
    setListCandidatesInfo([]);
    setPhoneCandidate('');
    setIdPosition('');
    setNamePosition_('');
    setMailCandidate('');
    setPriorityPosition('');
    setStatePosition('');
  };

  const openModalAddCandidates = (id) => {
    setVisibleModalAddAddCandidates(!visibleModalAddAddCandidates);
    setIdPosition(id);
    getListCandidates(id);
  };

  const openModalListCandidate = (id, tipo) => {
    setVisibleModalListCandidate(!visibleModalListCandidate);
  };

  const getListCandidates = async (id) => {
    try {
      let dataListCandidatesShow = [];
      let dataListCandidateInfo = [];
      setPhoneCandidate('');
      setMailCandidate('');

      get("api/candidate/list/position/" + id).then((result) => {
        if (result.data.length > 0) {
          result.data.forEach(element => {
            let item = { value: element.id, label: element.name_candidate + " " + element.lastname + " " + element.second_lastname };
            dataListCandidatesShow.push(item);
            dataListCandidateInfo.push(element)
          });
          setListCandidatesShow(dataListCandidatesShow);
          setListCandidatesInfo(dataListCandidateInfo);
        }
      });

    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = (data, e) => {
    e.preventDefault();

    const dataCandidate = {
      ...data,
      candidate_id: idCandidate,
      position_id: idPosition,
      user_id: localStorage.getItem('Id')
    }

    postWithData("api/position_candidate/store", dataCandidate).then((result) => {

      if (result.success === true) {
        setVisibleModalAddAddCandidates(false);
        //window.location.href = window.location.href;
        toast.success("Candidato propuesto para la vacante");
                
      } else {
        toast.error("Hubo un error al proponer candidato")
      }

    });

  };
  
  const handleChangeCandidate = data => {
    setPhoneCandidate('');
    setMailCandidate('');
    setIdCandidate(data.value);
    const foundElement = listCandidatesInfo.filter((item) => item.id === data.value);

    setPhoneCandidate(foundElement[0].phone)
    setMailCandidate(foundElement[0].email)
    /*
    get('api/candidate/info/' + data.value).then((element) => {
      setPhoneCandidate(element.data[0]['phone'])
      setMailCandidate(element.data[0]['email'])
    })*/

  };

  const handleToggleVisibleSearch = () => {
    setVisibleSearch(!visibleSearch);
  };

  const handleChangeCellValue = (rowIndex, columnName, newValue) => {
    // Copia profunda de los datos actuales
    const newData = listPositionData.map((row, index) => {
      if (index === rowIndex) {
        // Actualizar el valor de la celda en la columna específica
        return { ...row, [columnName]: newValue };
      }
      return row;
    });

    // Actualizar el estado con los datos modificados
    setListPositionData(newData);
  };

  const handleTogglePortal = () => {
    if((!isFilterPortal===true)) {
      setTogglePortalChange(1);
    } else {
      setTogglePortalChange(3);
    }
    setIsFilterNoPortal(false)
    setIsFilterPortal(!isFilterPortal);
  };

  const handleToggleNoPortal = () => {
    if((!isFilterNoPortal===true)) {
      setTogglePortalChange(2);
    } else {
      setTogglePortalChange(3);
    }
    setIsFilterPortal(false);
    setIsFilterNoPortal(!isFilterNoPortal);
  };

  const handleSearch = (e) => {
    
    const tempTableData = listPositionDataReserv;
    let textSearch = e.target.value;
    let filteredData;
    let tempSearchTextClient = searchTextClient;
    let tempSearchTextCodePosition= searchTextCodePosition ;
    let tempSearchTextPriority = searchTextPriority;
    let tempSearchTextStatus = searchTextStatus;
    let tempSearchTextId = searchTextId;
    let tempSearchTextPosition = searchTextPosition;
    let tempSearchTextResponsableReclutador = searchTextResponsableReclutador;
    let tempSearchTextDateRegister = searchTextDateRegister;

    switch (e.target.name) {
      case "client_search":
        setSearchTextClient(textSearch)
        tempSearchTextClient = textSearch;
        break;
      case "code_position_search":
        setSearchTextCodePosition(textSearch)
        tempSearchTextCodePosition = textSearch;
        break;
      case "position_search":
        setSearchTextPosition(textSearch)
        tempSearchTextPosition = textSearch;  
        break;
      case "priority_search":
        setSearchTextPriority(textSearch)
        tempSearchTextPriority = textSearch;
        break;
      case "status_search":
        setSearchTextStatus(textSearch)
        tempSearchTextStatus = textSearch;
        break;
      case "responsable_recruiter_search":
        setSearchTextResponsableReclutador(textSearch)
        tempSearchTextResponsableReclutador = textSearch;
        break;
      case "date_register_search":
        setSearchTextDateRegister(textSearch)
        tempSearchTextDateRegister = textSearch;
        break;
      case "id_search":
        setSearchTextId(textSearch)
        tempSearchTextId = textSearch;
        console.log("GOLA _ "+tempSearchTextId)
        break;
      
    }

    
    
    filteredData = tempTableData.filter((item) => item.name_client.toLowerCase().includes((tempSearchTextClient).toLowerCase()));
    filteredData = filteredData.filter((item) => item.code_position.toLowerCase().includes((tempSearchTextCodePosition).toLowerCase()));
    filteredData = filteredData.filter((item) => getBadgeTextContent(item.name).toLowerCase().includes((tempSearchTextPosition).toLowerCase()));
    filteredData = filteredData.filter((item) => item.priority.toLowerCase().includes((tempSearchTextPriority).toLowerCase()));
    filteredData = filteredData.filter((item) => item.enabled.toLowerCase().includes((tempSearchTextStatus).toLowerCase()));
    filteredData = filteredData.filter((item) => item.responsable.toLowerCase().includes((tempSearchTextResponsableReclutador).toLowerCase()));
    filteredData = filteredData.filter((item) => item.date_register.toLowerCase().includes((tempSearchTextDateRegister).toLowerCase()));
    filteredData = filteredData.filter((item) => (item.id).toString().includes(tempSearchTextId));

    setListPositionData(filteredData);

    //console.log(filteredData)

    let dataListExcel = [];
    filteredData.forEach(element => {
      
      let excelItem = {
        "Id": element.id,
        "Estado": element.enabled,
        "Cliente": element.name_client,
        "Vacante": element.name.props.children[1],
        "Responsable":element.responsable,
        "Fecha de Alta": (element.date_register).split(" ")[0],
        "Prioridad": element.priority,
        "Candidatos Activos": element.cant_vivos.props.children[0].props.children.props.children[1],
        "Candidatos Rechazados": element.cant_rechazados.props.children[1]
      }
      
      dataListExcel.push(excelItem);
    })

    setListPositionExcelData(dataListExcel);
  };

  const getBadgeTextContent = (badgeElement) => {
    if(badgeElement.props != undefined){
      const childrenArray = React.Children.toArray(badgeElement.props.children);
      return childrenArray.map((child) => child.props ? child.props.children : child).join('');
    } else {
      return '';
    }
  };

  const handleSelectStatusReject = (data) => {

    setSelectedStatusReject(data.value)
  }

  const clearInputs = () => {
    setSearchTextClient('');
    setSearchTextCodePosition('');
    setSearchTextPosition('');
    setSearchTextPriority('');
    setSearchTextStatus('');
    setSearchTextId('');
  };
  
  const showDetail = async (detail) => {
    setTitleModalDetail(detail.name)
    setDescriptions(detail.description)
    setReqDeseable(detail.desirable_requirements)
    setReqIndispenable(detail.required_requirements)
    setRateOrientative(detail.orientative)
    setVisibleModalDetail(true);
  }

  const showDetailListCandiate = async (list, idPosition) => {

    setVisibleModalListCandidate(!visibleModalListCandidate);

    let listCand = [];
    list.forEach(element => {

      let item = {
        "full_name_completo" : <Link to={`/candidate/history-candidate/${element.id}`} style={{ color: '#000', fontWeight: 'bold' }} target="_blank" rel="noopener noreferrer">{element.full_name_c}</Link>,
        "email" : element.email,
        "phone" : element.phone,
        "recruiter" : element.recruiter,
        "baja" : 
          <Button style={{ padding: 0,}} color='red' onClick={() => disassociatePosition(element.id, idPosition)} > 
            <i className= "icon-trash"></i> 
          </Button>
      };

      listCand.push(item)
    });

    setListCandidates(listCand)
    /*console.log(detail)
    setTitleModalDetail(detail.name)

    setDescriptions(detail.description)
    setReqDeseable(detail.desirable_requirements)
    setReqIndispenable(detail.required_requirements)
    setRateOrientative(detail.orientative)

    setVisibleModalDetail(true);*/
  }
  
  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(listPositionExcelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `rrhh_vacantes_${moment().format()}.xlsx`);
  }

  return (
    <Fragment>
      <Breadcrumb parent="" title="Lista de Vacantes" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                  
                <div className="toggle-container">
                  {/*<Button style={{ marginRight: '10px' }} 
                    className="btn-search" color="primary" 
                    onClick={handleToggleVisibleSearch}>{"Buscar Registro"}
                  </Button>*/ }
                  <Button style={{ marginRight: '10px' }}  className="btn-search" 
                    color={isFilterPortal ? 'success' : 'primary' } 
                    onClick={handleTogglePortal}>{"Portal"}
                  </Button> 
                  <Button className="btn-search" 
                    color={isFilterNoPortal ? 'success' : 'primary' } 
                    onClick={handleToggleNoPortal}>{"No Portal"}
                  </Button>
                  <Button color='success'  className="btn-search pull-right"
                    onClick={handleExport}>
                    <i className="fa fa-file-excel-o"></i> 
                      {" Exportar"}
                      </Button>
                  <div className={`content ${visibleSearch ? 'visible' : 'hidden'}`}>
                    <Row>
                      <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="id_search" value={searchTextId} onChange={handleSearch} placeholder="Id" />
                        </div>
                      </Col>
                      <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="status_search" value={searchTextStatus} onChange={handleSearch} placeholder="Estado" />
                        </div>
                      </Col>
                      <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="client_search" value={searchTextClient} onChange={handleSearch} placeholder="Cliente" />
                        </div>
                      </Col>
                      <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="responsable_recruiter_search" value={searchTextResponsableReclutador} onChange={handleSearch} placeholder="Reclutador Responasble" />
                        </div>
                      </Col>
                      <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="position_search" value={searchTextPosition} onChange={handleSearch} placeholder="Vacante" />
                        </div>
                      </Col>
                      <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="priority_search" value={searchTextPriority} onChange={handleSearch} placeholder="Prioridad" />
                        </div>
                      </Col>
                      <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="date_register_search" value={searchTextDateRegister} onChange={handleSearch} placeholder="Fecha de Alta" />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                    <DataTable
                      columns={listPositionsDataGeneral}
                      data={listPositionData}
                      persistTableHead
                      searchable={true}
                      paginationPerPage={30}  
                      pagination
                      noDataComponent={<div>No hay datos disponibles</div>}
                      progressPending={loading}
                      progressComponent={<div className="loader-box"><div className="loader-9"></div></div>} // Componente personalizado para el indicador de carga
                      customStyles={{
                        head: {
                          style: {
                            textAlign: 'center',
                          },
                        },
                      }}
                    />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>

      <Modal size="lg" isOpen={visibleModalAddAddCandidates} toggle={openModalAddCandidates}>
        <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={openModalAddCandidates}>
            {"Proponer Candidato"}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg="6">
                <div className="col-form-label pt-0">{"Candidato"}:</div>
                <FormGroup>
                  <Select
                    options={listCandidatesShow}
                    id="id"
                    onChange={handleChangeCandidate}
                    innerRef={register({ required: true })} />
                </FormGroup>
              </Col>
              <Col>
                {"Teléfono:"}
                <p>{phoneCandidate}</p>
                {"Correo:"}
                <p>{mailCandidate}</p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={closeModal}>{"Cancelar"}</Button>
            <Button color="primary mr-1" type="submit">{"Asignar"}</Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal size="lg" isOpen={visibleModalDetail} toggle={openModalDetail}>
          <ModalHeader toggle={openModalDetail}>
            {titleModalDetail}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col  md={12} sm={12}>
                <div>
                  <h6>Descripción:</h6> 
                  <p>{descriptions}</p>  
                </div>
              </Col>
            </Row>
            <Row>
              <Col  md={6} sm={12}>
                <div>
                  <h6>Requerimientos Deseables:</h6> 
                  <p>{reqDeseable}</p>  
                </div>
              </Col>
              <Col  md={6} sm={12}>
                <div>
                  <h6>Requerimientos Indispensables:</h6> 
                  <p>{reqIndispenable}</p>  
                </div>
              </Col>
            </Row>
            <Row>
              <Col  md={6} sm={12}>
                <div>
                  <h6>Tarifa orientativa:</h6> 
                  <p>{rateOrientative}</p>  
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

      <Modal size="lg" isOpen={visibleModalListCandidate} toggle={openModalListCandidate}>
        <ModalBody>
          <DataTable
            data={listCandidates}
            columns={tableColumnsCandidate}
            striped={true}
            center={true}
            persistTableHead
          />
        </ModalBody>
      </Modal>

      <Modal isOpen={visibleModalDeletePosition} toggle={openModalDeletePosition}>
        <Form className="theme-form">
          <ModalHeader toggle={openModalDeletePosition}>
            {"Elimiar Vacante"}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                {"¿Estas seguro de eliminar la vacante: "}{namePosition_}{"?"}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary mr-1" onClick={deleteRegister} type="button">{"Si"}</Button>
            <Button color="secondary" onClick={closeModal}>{"Cancelar"}</Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={visibleModalEditPriority} toggle={openModalEditPriority}>
        <Form className="theme-form">
          <ModalHeader toggle={openModalEditPriority}>
            {"Cambiar Prioridad"}
            {showMaviseMessgeModal ?  <div style={{ color: 'red', fontSize: '14px' }}>Tienes varios items seleccionados, cambiaras la prioridad de varias posiciones</div> : null}
        
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                    <Label htmlFor="priority">{"Prioridad"}:</Label>
                    <Select 
                    onChange={handleSelectPriority} 
                    options={optionsPriority} 
                    id="priority" 
                    name="priority" 
                    value={priorityValue} 
                    innerRef={register()} />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary mr-1" onClick={changePrioriy} type="button">{"Si"}</Button>
            <Button color="secondary" onClick={closeModal}>{"Cancelar"}</Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={visibleModalEditState} toggle={openModalEditState}>
        <Form className="theme-form">
          <ModalHeader toggle={openModalEditState}>
            {"Cambiar Estado"}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                    <Label htmlFor="state">{"Estado de la Vacante"}:</Label>
                    <Select onChange={handleSelectState} options={listStatus} id="state" name="state" value={selectedStatus} innerRef={register()} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                    <Label htmlFor="state">{"Sub Estado de la Vacante"}:</Label>
                    <Select onChange={handleSelectSubState} options={listSubStatus} id="sub_state" name="sub_state" value={selectedSubStatus} innerRef={register()} />
                </FormGroup>
              </Col>
            </Row>
            {showInputMotivo ? (
            <Row>
              <Col>
                <FormGroup>
                    <Label className="col-form-label pt-0">{"Otro Motivo"}:</Label>
                    <Input type="textarea" className="form-control btn-square" name="motivo_status" onChange={handleChangeMotivo} value={motivoStatus} rows="3" innerRef={register()} />
                </FormGroup>
              </Col>
            </Row>
              ) : (
                  <></>
                  )
              }
          </ModalBody>
          <ModalFooter>
            <Button color="primary mr-1" onClick={changeState} type="button">{"Si"}</Button>
            <Button color="secondary" onClick={closeModal}>{"Cancelar"}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default CandidateList;