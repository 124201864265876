import React, { Fragment,useState,useEffect} from 'react';

import Breadcrumb from '../layout/breadcrumb'
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component'
import * as XLSX from 'xlsx';
import moment from 'moment';
import Select from 'react-select'
import {Container,Row,Col,Card,CardBody,Button,ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input} from 'reactstrap'
import { get, postWithData } from '../services/api'
import '../assets/css/tablestyle.css';
import { data } from '../components/charts/chartsjs/chartsData';

const ResponsbleClientList = () =>  {

  const [listClientData, setListClientData] = useState([]);
  const [listClientResponsableData, setListClientResponsableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleSearch, setVisibleSearch] = useState(true);
  const [searchTextName, setSearchTextName] = useState('');
  const [searchTextClientName, setSearchTextLegalName] = useState('');
  const [searchTextPhone, setSearchTextPhone] = useState('');
  const [searchTextEmail, setSearchTextEmail] = useState('');
  const [visibleModalDelete, setVisibleModalDelete] = useState(false);
  const [visibleModalAdd, setVisibleModalAdd] = useState(false);
  const [listClientsData, setListClientsData] = useState([]);
  
  const [idClient, setIdClient] = useState(null);
  const [clientValue, setClientValue] = useState();

  const [nameClient, setNameClient] = useState(null);

  const [nameResp, setNameResp] = useState(null);
  const [phoneResp, setPhoneResp] = useState(null);
  const [emailResp, setEmailResp] = useState(null);

  useEffect(() => {
    getListResponsablesClient();
    getListClients();
  },[0])

  const getListResponsablesClient = async () => {
    
    try {
      let dataList = [];

      get("api/client/responsables/list").then((result) => { 
        if(result.data.length > 0){
          
          result.data.forEach(element => {
            let item = {
                "id": element.id,
                "client_name": element.client_name ?? '',
                "name": element.name ?? '',
                "phone": element.phone ?? '',
                "email": element.email ?? '',
                "actions": 
                <ButtonGroup>
                  <Button style={{ padding: 5, hight: '35px', width: '35px' }} color='red'  title='Eliminar' onClick={() => openModalDelete(element.id, element.name)}><i className= "icon-trash"></i></Button>
                </ButtonGroup>
            }
            dataList.push(item);
          });
          setListClientResponsableData(dataList);
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getListClients = async () => {
    let dataRes = [];
    try {
        get("api/client/list").then((result) => {
            if (result.success == true) {
                result.data.forEach(element => {
                    dataRes.push({ value: element.id, label: element.name })
                    /*if (idClient == element.id) {
                        setClientValue({ value: element.id, label: element.name });
                    }*/
                });
                setListClientsData(dataRes)
            }
        });
    } catch (error) {
        console.log(error)
    }
}

  const closeModal = () => {
    setVisibleModalDelete(false);
    setNameClient('');
    setIdClient('');
  };

  const openModalDelete = (id, name) => {
    setNameClient(name);
    setIdClient(id);
    setVisibleModalDelete(true);
  }

  const openModalAdd = (id, name) => {
    setNameClient(name);
    setIdClient(id);
    setVisibleModalAdd(true);
  }

  const addRegister = () => {
    let user_id = localStorage.getItem('Id');
    
    let data = {
      'name' : nameResp,
      'client_id' : idClient,
      'phone' : phoneResp,
      'email' : emailResp
    }

    console.log(data);

    try {
      postWithData("api/client/responsables/add", data).then((result) => {
        console.log(result)
        setVisibleModalAdd(false);
        setNameResp('')
        setPhoneResp('')
        setEmailResp('')
        setIdClient('')
        toast.success(`Se agrego de forma satisfactoria.`);
                    
      });
    } catch (error) {
      console.log(error)
    }

  }

  const deleteRegister = () => {
    let user_id = localStorage.getItem('Id');
    console.log(idClient)
    try {
      get("api/client/delete/" + idClient + '/' + user_id).then((result) => {
        console.log(result.data)
        const newDatalist = listClientData.filter(item => item.id !== idClient);
        setListClientData(newDatalist);
        setVisibleModalDelete(false);
        toast.success(`Se elimino de forma satisfactoria.`);
                    
      });
    } catch (error) {
      console.log(error)
    }
  }

  const tableColumns = [
    
    /*{
      name: 'Acciones',
      selector: 'actions',
      sortable: true,
      center:true,
    },*/
    {
      name: 'Nombre Cliente',
      selector: 'client_name',
      sortable: true,
      center:false,
    },
    {
      name: 'Nombre',
      selector: 'name',
      sortable: true,
      center:false,
    },
    {
      name: 'Telefono',
      selector: 'phone',
      sortable: true,
      center:false,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      center:false,
    },
    /*{
      name: 'Acciones',
      selector: 'actions',
      sortable: true,
      center: true,
      width: '200px'
    }*/
  ]
  
  const handleToggleVisibleSearch = () => {
    setVisibleSearch(!visibleSearch);
  };

  const handleSelectClient = (data) => {
    setIdClient(data.value);
    setClientValue({ value: data.value, label: data.label });
};

  const handleChange = (event) => {

    switch (event.target.name) {
      case "name":
        setNameResp(event.target.value)
        break;
      case "phone":
        setPhoneResp(event.target.value)
        break;
      case "email":
        setEmailResp(event.target.value)
        break;
    }
  }

  const handleSearch = (e) => {

    const tempTableData = listClientResponsableData;
    let textSearch = e.target.value;
    let filteredData;

    let tempSearchTextName = searchTextName
    let tempSearchTextClientName = searchTextClientName
    let tempSearchTextPhone = searchTextPhone
    let tempSearchTextEmail = searchTextEmail

    switch (e.target.name) {
      case "name_search":
        setSearchTextName(textSearch)
        tempSearchTextName = textSearch;
        break;
      case "phone_search":
        setSearchTextPhone(textSearch)
        tempSearchTextPhone = textSearch;
        break;
      case "email_search":
        setSearchTextEmail(textSearch)
        tempSearchTextEmail = textSearch;  
        break;
      case "client_name_search":
        setSearchTextLegalName(textSearch)
        tempSearchTextClientName = textSearch;
        break;
    }

   

    filteredData = tempTableData.filter((item) => item.name.toLowerCase().includes((tempSearchTextName).toLowerCase()));
    filteredData = filteredData.filter((item) => item.phone.toLowerCase().includes((tempSearchTextPhone).toLowerCase()));
    filteredData = filteredData.filter((item) => item.client_name.toLowerCase().includes((tempSearchTextClientName).toLowerCase()));
    filteredData = filteredData.filter((item) => item.email.toLowerCase().includes((tempSearchTextEmail).toLowerCase()));
    
    setListClientResponsableData(filteredData);

  };

  const handleAdd = () => {
    setVisibleModalAdd(true);
  }

  const handleExport = () => {
    var candidateExcelData = listClientData.map(cliente => {
      return {
        "Nombre Cliente": cliente.legal_name,
        "Nombre": cliente.name,
        "Teléfono": cliente.phone,
        "Email": cliente.email
      }
    })
    var worksheet = XLSX.utils.json_to_sheet(candidateExcelData);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `rrhh_clientes_responsables_${moment().format()}.xlsx`);
  }

    return (
        <Fragment>
          <Breadcrumb parent="" title="Lista de Responsables por Cliente"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                            <div className="toggle-container">
                              {/*<Button className="btn-search" color="primary" 
                              onClick={handleToggleVisibleSearch}>{"Buscar Registro"}</Button>*/}
                              <Button color='success' className="btn-search pull-right"
                                onClick={handleExport}>
                                  <i className="fa fa-file-excel-o"></i> 
                                  {" Exportar"}
                              </Button>
                              <Button color='primary mr-1' className="btn-search pull-right"
                                onClick={handleAdd}>
                                  <i className="fa fa-plus"></i> 
                                  {" Agregar nuevo"}
                              </Button>
                              <div className={`content ${visibleSearch ? 'visible' : 'hidden'}`}>
                                <Row>
                                  <Col sm="2">
                                    <div className="search-container">
                                      <input type="text" className="form-control" name="name_search" value={searchTextName} onChange={handleSearch} placeholder="Nombre" />
                                    </div>
                                  </Col>
                                  <Col sm="2">
                                    <div className="search-container">
                                      <input type="text" className="form-control" name="client_name_search" value={searchTextClientName} onChange={handleSearch} placeholder="Nombre Cliente" />
                                    </div>
                                  </Col>
                                  <Col sm="2">
                                    <div className="search-container">
                                      <input type="text" className="form-control" name="phone_search" value={searchTextPhone} onChange={handleSearch} placeholder="Teléfono" />
                                    </div>
                                  </Col>
                                  <Col sm="2">
                                    <div className="search-container">
                                      <input type="text" className="form-control" name="email_search" value={searchTextEmail} onChange={handleSearch} placeholder="Email" />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                              <DataTable
                                data={listClientResponsableData}
                                pagination
                                columns={tableColumns}
                                striped={true}
                                persistTableHead
                                searchable={true}
                                paginationPerPage={30}  
                                noDataComponent={<div>No hay datos disponibles</div>}
                                progressPending={loading}
                                progressComponent={<div className="loader-box"><div className="loader-9"></div></div>} // Componente personalizado para el indicador de carga
                                customStyles={{
                                  head: {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  },
                                }}
                              />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </Container>

            <Modal isOpen={visibleModalDelete} toggle={openModalDelete}>
              <Form className="theme-form">
                <ModalHeader toggle={openModalDelete}>
                  {"Elimiar Cliente"}
                </ModalHeader>
                <ModalBody>
                  <Row>
                    <Col>
                      {"¿Estas seguro de eliminar el cliente: "}{nameClient}{"?"}
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary mr-1" onClick={deleteRegister} type="button">{"Si"}</Button>
                  <Button color="secondary" onClick={closeModal}>{"Cancelar"}</Button>
                </ModalFooter>
              </Form>
            </Modal>
            <Modal isOpen={visibleModalAdd} toggle={openModalAdd}>
              <Form className="theme-form">
                <ModalHeader toggle={openModalAdd}>
                  {"Elimiar Cliente"}
                </ModalHeader>
                <ModalBody>
                  <Row>
                      <Col>
                        <FormGroup>
                            <Label htmlFor="client">{"Cliente/Marca"}:<span className="font-danger">*</span></Label>
                            <Select onChange={handleSelectClient}
                                options={listClientsData} id="client" name="client" value={clientValue} />
                        </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                      <Col>
                        <FormGroup>
                            <Label htmlFor="name">{"Nombre"}:<span className="font-danger">*</span></Label>
                            <Input className="form-control" id="name" name="name" type="text" placeholder="" onChange={handleChange} defaultValue={''} />
                        </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                        <FormGroup>
                            <Label htmlFor="name">{"Email"}:<span className="font-danger">*</span></Label>
                            <Input className="form-control" id="email" name="email" type="text" placeholder="" onChange={handleChange} defaultValue={''} />
                        </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                        <FormGroup>
                            <Label htmlFor="name">{"Teléfono"}:<span className="font-danger">*</span></Label>
                            <Input className="form-control" id="phone" name="phone" type="text" placeholder="" onChange={handleChange} defaultValue={''} />
                        </FormGroup>
                      </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary mr-1" onClick={addRegister} type="button">{"Agregar"}</Button>
                  <Button color="secondary" onClick={closeModal}>{"Cancelar"}</Button>
                </ModalFooter>
              </Form>
            </Modal>
        </Fragment>
    );

};

export default ResponsbleClientList;