import React, { Fragment,useState,useEffect} from 'react';

import Breadcrumb from '../layout/breadcrumb'
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component'
import * as XLSX from 'xlsx';
import moment from 'moment';
import Select from 'react-select'
import {Container,Row,Col,Card,CardBody,Button,ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input} from 'reactstrap'
import { get, postWithData } from '../services/api'
import '../assets/css/tablestyle.css';
import { data } from '../components/charts/chartsjs/chartsData';

const ReportProcess = () =>  {

  const [listData, setListData] = useState([]);
  const [listClientResponsableData, setListClientResponsableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleSearch, setVisibleSearch] = useState(true);
  const [searchTextName, setSearchTextName] = useState('');
  const [searchTextClientName, setSearchTextLegalName] = useState('');
  const [searchTextPhone, setSearchTextPhone] = useState('');
  const [searchTextEmail, setSearchTextEmail] = useState('');
  const [visibleModalDelete, setVisibleModalDelete] = useState(false);
  const [visibleModalAdd, setVisibleModalAdd] = useState(false);
  const [listClientsData, setListClientsData] = useState([]);
  
  const [idClient, setIdClient] = useState(null);
  const [clientValue, setClientValue] = useState();

  const [nameClient, setNameClient] = useState(null);

  const [nameResp, setNameResp] = useState(null);
  const [phoneResp, setPhoneResp] = useState(null);
  const [emailResp, setEmailResp] = useState(null);

  useEffect(() => {
    getListData();
  },[0])

  const getListData = async () => {
    
    try {
      let dataList = [];

      get("api/position/report/meeting").then((result) => { 
        if(result.data.length > 0){
          
          result.data.forEach(element => {
            let item = {
              "id": element.id,
              "anio": element.anio ?? '',
              "month": element.month_ ?? '',
              "fecha_apertura": element.fecha_apertura ?? '',
              "fecha_cierre":element.fecha_cierre ?? '',
              "prioridad": element.prioridad ?? '',
              "recruiter": element.recruiter ?? '',
              "tecnologia": element.tecnologia ?? '',
              "cliente_id": element.cliente_id ?? '',
              "client_nombre": element.client_nombre ?? '',
              "vacante_id": element.vacante_id ?? '',
              "vacante_nombre": element.vacante_nombre ?? '',
              "nn": element.nn ?? '',
              "observaciones": element.observaciones ?? '',
              "estado_id": element.estado_id ?? '',
              "estado_nombre": element.estado_nombre ?? '',
              "motivo_cierre": element.motivo_cierre ?? '',
               
            }
            dataList.push(item);
          });
          setListData(dataList);
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

/*
  const closeModal = () => {
    setVisibleModalDelete(false);
    setNameClient('');
    setIdClient('');
  };

  const openModalDelete = (id, name) => {
    setNameClient(name);
    setIdClient(id);
    setVisibleModalDelete(true);
  }

  const openModalAdd = (id, name) => {
    setNameClient(name);
    setIdClient(id);
    setVisibleModalAdd(true);
  }
  

  const addRegister = () => {
    let user_id = localStorage.getItem('Id');
    
    let data = {
      'name' : nameResp,
      'client_id' : idClient,
      'phone' : phoneResp,
      'email' : emailResp
    }

    console.log(data);

    try {
      postWithData("api/client/responsables/add", data).then((result) => {
        console.log(result)
        setVisibleModalAdd(false);
        setNameResp('')
        setPhoneResp('')
        setEmailResp('')
        setIdClient('')
        toast.success(`Se agrego de forma satisfactoria.`);
                    
      });
    } catch (error) {
      console.log(error)
    }

  }

  const deleteRegister = () => {
    let user_id = localStorage.getItem('Id');
    console.log(idClient)
    try {
      get("api/client/delete/" + idClient + '/' + user_id).then((result) => {
        console.log(result.data)
        const newDatalist = listClientData.filter(item => item.id !== idClient);
        setListClientData(newDatalist);
        setVisibleModalDelete(false);
        toast.success(`Se elimino de forma satisfactoria.`);
                    
      });
    } catch (error) {
      console.log(error)
    }
  }

  */
  const tableColumns = [
    {
      name: 'Año',
      selector: 'anio',
      sortable: true,
      center:false,
    },
    {
      name: 'Mes',
      selector: 'month',
      sortable: true,
      center:false,
    },
    {
      name: 'Fecha de Apertura',
      selector: 'fecha_apertura',
      sortable: true,
      center:false,
    },
    {
      name: 'Fecha de Cierre',
      selector: 'fecha_cierre',
      sortable: true,
      center:false,
    },
    {
      name: 'Prioridad',
      selector: 'prioridad',
      sortable: true,
      center:false,
    },
    {
      name: 'Reclutador',
      selector: 'recruiter',
      sortable: true,
      center:false,
    },
    {
      name: 'Cliente',
      selector: 'client_nombre',
      sortable: true,
      center:false,
    },
    {
      name: 'Proceso`',
      selector: 'vacante_nombre',
      sortable: true,
      center:false,
    },
    {
      name: 'NN',
      selector: 'observaciones',
      sortable: true,
      center:false,
    },
    {
      name: 'Observaciones',
      selector: 'observaciones',
      sortable: true,
      center:false,
    },
    {
      name: 'Estado',
      selector: 'estado_nombre',
      sortable: true,
      center:false,
    },
    {
      name: ' ',
      selector: 'observaciones',
      sortable: true,
      center:false,
    },
    {
      name: 'Motivo Cierre',
      selector: 'motivo_cierre',
      sortable: true,
      center:false,
    }
  ]
  
  const handleToggleVisibleSearch = () => {
    setVisibleSearch(!visibleSearch);
  };

  const handleSelectClient = (data) => {
    setIdClient(data.value);
    setClientValue({ value: data.value, label: data.label });
};

  const handleChange = (event) => {

    switch (event.target.name) {
      case "name":
        setNameResp(event.target.value)
        break;
      case "phone":
        setPhoneResp(event.target.value)
        break;
      case "email":
        setEmailResp(event.target.value)
        break;
    }
  }
/*
  const handleSearch = (e) => {

    const tempTableData = listClientResponsableData;
    let textSearch = e.target.value;
    let filteredData;

    let tempSearchTextName = searchTextName
    let tempSearchTextClientName = searchTextClientName
    let tempSearchTextPhone = searchTextPhone
    let tempSearchTextEmail = searchTextEmail

    switch (e.target.name) {
      case "name_search":
        setSearchTextName(textSearch)
        tempSearchTextName = textSearch;
        break;
      case "phone_search":
        setSearchTextPhone(textSearch)
        tempSearchTextPhone = textSearch;
        break;
      case "email_search":
        setSearchTextEmail(textSearch)
        tempSearchTextEmail = textSearch;  
        break;
      case "client_name_search":
        setSearchTextLegalName(textSearch)
        tempSearchTextClientName = textSearch;
        break;
    }

   

    filteredData = tempTableData.filter((item) => item.name.toLowerCase().includes((tempSearchTextName).toLowerCase()));
    filteredData = filteredData.filter((item) => item.phone.toLowerCase().includes((tempSearchTextPhone).toLowerCase()));
    filteredData = filteredData.filter((item) => item.client_name.toLowerCase().includes((tempSearchTextClientName).toLowerCase()));
    filteredData = filteredData.filter((item) => item.email.toLowerCase().includes((tempSearchTextEmail).toLowerCase()));
    
    setListClientResponsableData(filteredData);

  };

  const handleAdd = () => {
    setVisibleModalAdd(true);
  }
   */

  const handleExport = () => {
    var candidateExcelData = listData.map(data => {
      return {
          "Año": data.anio,
          "Mes": data.month,
          "Fecha de Apertura": data.fecha_apertura,
          "Fecha de Cierre":data.fecha_cierre,
          "Prioridad": data.prioridad,
          "Recruiter": data.recruiter,
          "Tecnologia": data.tecnologia,
          "Cliente": data.client_nombre,
          "Vacante": data.vacante_nombre,
          "NN": data.nn,
          "Observaciones": data.observaciones,
          "Estado": data.estado_nombre,
          " ": data.nn,
          "Motivo Cierre": data.motivo_cierre
      }
    })
    var worksheet = XLSX.utils.json_to_sheet(candidateExcelData);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `report_process_${moment().format()}.xlsx`);
  }

    return (
        <Fragment>
          <Breadcrumb parent="" title="Reporte de Procesos"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                            <div className="toggle-container">
                              {/*<Button className="btn-search" color="primary" 
                              onClick={handleToggleVisibleSearch}>{"Buscar Registro"}</Button>*/}
                              <Button color='success' className="btn-search pull-right"
                                onClick={handleExport}>
                                  <i className="fa fa-file-excel-o"></i> 
                                  {" Exportar"}
                              </Button>
                              {/*<Button color='primary mr-1' className="btn-search pull-right"
                                onClick={handleAdd}>
                                  <i className="fa fa-plus"></i> 
                                  {" Agregar nuevo"}
                              </Button>
                              <div className={`content ${visibleSearch ? 'visible' : 'hidden'}`}>
                                <Row>
                                  <Col sm="2">
                                    <div className="search-container">
                                      <input type="text" className="form-control" name="name_search" value={searchTextName} onChange={handleSearch} placeholder="Nombre" />
                                    </div>
                                  </Col>
                                  <Col sm="2">
                                    <div className="search-container">
                                      <input type="text" className="form-control" name="client_name_search" value={searchTextClientName} onChange={handleSearch} placeholder="Nombre Cliente" />
                                    </div>
                                  </Col>
                                  <Col sm="2">
                                    <div className="search-container">
                                      <input type="text" className="form-control" name="phone_search" value={searchTextPhone} onChange={handleSearch} placeholder="Teléfono" />
                                    </div>
                                  </Col>
                                  <Col sm="2">
                                    <div className="search-container">
                                      <input type="text" className="form-control" name="email_search" value={searchTextEmail} onChange={handleSearch} placeholder="Email" />
                                    </div>
                                  </Col>
                                </Row>
                              </div>*/}
                            </div>
                              <DataTable
                                data={listData}
                                pagination
                                columns={tableColumns}
                                striped={true}
                                persistTableHead
                                searchable={true}
                                paginationPerPage={30}  
                                noDataComponent={<div>No hay datos disponibles</div>}
                                progressPending={loading}
                                progressComponent={<div className="loader-box"><div className="loader-9"></div></div>} // Componente personalizado para el indicador de carga
                                customStyles={{
                                  head: {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  },
                                }}
                              />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </Container>

            {/*<Modal isOpen={visibleModalDelete} toggle={openModalDelete}>
              <Form className="theme-form">
                <ModalHeader toggle={openModalDelete}>
                  {"Elimiar Cliente"}
                </ModalHeader>
                <ModalBody>
                  <Row>
                    <Col>
                      {"¿Estas seguro de eliminar el cliente: "}{nameClient}{"?"}
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary mr-1" onClick={deleteRegister} type="button">{"Si"}</Button>
                  <Button color="secondary" onClick={closeModal}>{"Cancelar"}</Button>
                </ModalFooter>
              </Form>
            </Modal>
            <Modal isOpen={visibleModalAdd} toggle={openModalAdd}>
              <Form className="theme-form">
                <ModalHeader toggle={openModalAdd}>
                  {"Elimiar Cliente"}
                </ModalHeader>
                <ModalBody>
                  <Row>
                      <Col>
                        <FormGroup>
                            <Label htmlFor="client">{"Cliente/Marca"}:<span className="font-danger">*</span></Label>
                            <Select onChange={handleSelectClient}
                                options={listClientsData} id="client" name="client" value={clientValue} />
                        </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                      <Col>
                        <FormGroup>
                            <Label htmlFor="name">{"Nombre"}:<span className="font-danger">*</span></Label>
                            <Input className="form-control" id="name" name="name" type="text" placeholder="" onChange={handleChange} defaultValue={''} />
                        </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                        <FormGroup>
                            <Label htmlFor="name">{"Email"}:<span className="font-danger">*</span></Label>
                            <Input className="form-control" id="email" name="email" type="text" placeholder="" onChange={handleChange} defaultValue={''} />
                        </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                        <FormGroup>
                            <Label htmlFor="name">{"Teléfono"}:<span className="font-danger">*</span></Label>
                            <Input className="form-control" id="phone" name="phone" type="text" placeholder="" onChange={handleChange} defaultValue={''} />
                        </FormGroup>
                      </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary mr-1" onClick={addRegister} type="button">{"Agregar"}</Button>
                  <Button color="secondary" onClick={closeModal}>{"Cancelar"}</Button>
                </ModalFooter>
              </Form>
            </Modal>*/}
        </Fragment>
    );

};

export default ReportProcess;