import React,{useState,useEffect} from 'react';
import man from '../assets/images/dashboard/profile.png';
import {Container,Row,Col,CardBody,Form,FormGroup,Input,Label,Button} from 'reactstrap'
import {firebase_app,googleProvider,facebookProvider,twitterProvider,githubProvider, Jwt_token } from '../data/config'
import { handleResponse } from '../services/fack.backend'
import { useAuth0 } from '@auth0/auth0-react'
import { toast } from 'react-toastify';
import {withRouter} from 'react-router-dom'
import { Login,GooglePlus, SignUp,YourName,Password,Facebook,Twitter,RememberMe,LOGIN,LoginWithAuth0,Github,LoginWithJWT } from '../constant';
import { login } from '../services/api'


const Logins = (props) => {
  
  const {loginWithRedirect} = useAuth0()
  //const [email, setEmail] = useState("admin@ioon.es");
  //const [password, setPassword] = useState("trytest");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false) 

  const [value, setValue] = useState(
      localStorage.getItem('profileURL' || man)
  );
  const [name, setName] = useState(
      localStorage.getItem('Name')
  );

  const [id, setId] = useState(
    localStorage.getItem('Id')
);

  useEffect(() => {

  //localStorage.setItem('profileURL', value);
  //localStorage.setItem('Name', name);
  //localStorage.setItem('Id', id);
  }, [value,name,id]);

  const loginAuth = async () => {
    
    try {
      setLoading(true)

      let data = { email, password };
      
      login("api/login", data).then((result) => { 
        
        if(result.success == true){
          setValue(man);
          setName(result.data.name);
          setId(result.data.id);
          
          localStorage.setItem("authenticated",true)
          localStorage.setItem('profileURL', man);
          localStorage.setItem('Name', result.data.name);
          localStorage.setItem('NameRole', result.data.role_name.name);
          localStorage.setItem('Id', result.data.id);
          localStorage.setItem('token', result.data.token);
          
          props.history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
        }else{
          toast.error("Oppss.. La contraseña es invalidad o el usuario no existe.");
        }

      });
    } catch (error) {
      setTimeout(() => {
          toast.error("Oppss.. Ha habido un problema, intente nuevamente en unos minutos.");
      }, 200);
    }
  }


const googleAuth = async () => {
  
  try {
          firebase_app.auth().signInWithPopup(googleProvider).then(function (result) {
          setName(result.user.displayName);
          setValue(result.user.photoURL)
          localStorage.setItem('token', Jwt_token);
          setTimeout(() => {
            props.history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
          }, 200);
         
      });
  } catch (error) {
      setTimeout(() => {
          toast.error("Oppss.. The password is invalid or the user does not have a password.");
      }, 200);
  }
};

const facebookAuth = async () => {
  
  try {
          firebase_app.auth().signInWithPopup(facebookProvider).then(function (result) {
          setValue(result.user.photoURL);
          setName(result.user.displayName)
          localStorage.setItem('token', Jwt_token);
          setTimeout(() => {
            props.history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
          }, 200);
      });
  } catch (error) {
      setTimeout(() => {
          toast.error("Oppss.. The password is invalid or the user does not have a password.");
      }, 200);
  }
}

const twitterAuth = async () => {
 
  try {
          firebase_app.auth().signInWithPopup(twitterProvider).then(function (result) {
          setValue(result.user.photoURL);
          setName(result.user.displayName)
          localStorage.setItem('token', Jwt_token);
          setTimeout(() => {
            props.history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
          }, 200);
      });
  } catch (error) {
      setTimeout(() => {
          toast.error("Oppss.. The password is invalid or the user does not have a password.");
      }, 200);
  }
}

const githubAuth = async () => {
  
  try {
          firebase_app.auth().signInWithPopup(githubProvider).then(function (result) {
          setValue(result.user.photoURL);
          setName("Hardik Parmar")
          localStorage.setItem('token', Jwt_token);
          setTimeout(() => {
            props.history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
          }, 200);
      });
  } catch (error) {
      setTimeout(() => {
          toast.error("Oppss.. The password is invalid or the user does not have a password.");
      }, 200);
  }
}

const loginWithJwt = (email,password) => {
  
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: ({ email, password })
  };
  
  return fetch('/users/authenticate', requestOptions)
  .then(handleResponse)
  .then(user => {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    setValue(man);
    setName("Emay Walter");
    localStorage.setItem('token', user);
    window.location.href = `${process.env.PUBLIC_URL}/dashboard/default`
    return user;
  });
}

    return (
        <div className="page-wrapper">
        <Container fluid={true} className="p-0">
          <div className="authentication-main m-0">
            <div className="auth-innerright">
              <div className="authentication-box">
                <CardBody className="h-100-d-center">
                  <div className="cont text-center b-light" style={{ width:500 }}>
                    <div className='' style={{ width:'100%' }}> 
                      <Form className="theme-form" style={{ width:'100%' }}>
                        <img className="img-fluid for-light" src={require("../assets/images/logo/logo_dark2.png")} width={300} style={{ marginBottom:50 }} alt="" />
            
                        <h4>{"Iniciar Sesión"}</h4>
                        <FormGroup>
                          <Label className="col-form-label pt-0"></Label>
                          <Input className="form-control" type="email" placeholder='Email' onChange={e => setEmail(e.target.value)} defaultValue={email} required=""/>
                        </FormGroup>
                        <FormGroup>
                          <Label className="col-form-label"></Label>
                          <Input className="form-control" type="password" placeholder='Contraseña' onChange={e => setPassword(e.target.value)} defaultValue={password}  required=""/>
                        </FormGroup>
                        <FormGroup className="form-row mt-3 mb-0">
                          {loading ?
                          <Button color="primary btn-block" disabled={loading}>
                            {"Cargando..."}
                          </Button>
                          :
                          <Button color="primary btn-block" onClick={() => loginAuth()}>
                              {"Ingresar"}
                          </Button>
                          }
                          <div style={{ alignItems: 'center', textAlign: 'center', width: '100%' }}>
                            <img className="img-fluid for-light" src={require("../assets/images/logo/LAB_ES.png")} width={100} style={{ marginTop:20 }} alt="" />
                            <img className="img-fluid for-light" src={require("../assets/images/logo/RGB_EN.png")} width={100} style={{ marginTop:20 }} alt="" />
                          </div>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </CardBody>
              </div>
            </div>
          </div>
          </Container>
        </div>
    );
}

export default withRouter(Logins);